.ql-topic-c{
    display: flex;
    border: 1px solid rgb(247, 247, 247);
    background: white;
   box-shadow:  0 1px 2px 0 rgb(210 231 248 / 30%), 0 1px 3px 1px rgb(172 184 193 / 15%);
    padding: 6px 12px;
    border-radius: 9px;
    margin: 8px;
    cursor: pointer;
}
.ql-topic-c:hover{
    border: 1px solid rgb(131 156 229);
    background: rgb(213, 226, 253);
}
.ql-topic-c.active{
    border: 1px solid green;
}
.ql-topic-c.selected{
    border: 1px solid rgb(131 156 229);
    background: rgb(229 232 255);
}
.ql-topic-c .ql-topic{
    flex: 1;
    color: #555;
    font-weight: 600;
    align-self: center;
}
.ql-topic-status{
    align-self: center;
}
.ql-topic-status .MuiButton-outlinedPrimary{
    border-radius: 12px !important;
    text-transform: none;
}