.audit-logo table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
.audit-logo th, td {
    text-align: left;
    padding: 8px;
  }
  
.audit-logo tr:nth-child(even){background-color: #f2f2f2}