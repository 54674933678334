.products-c tr th:first-child, tr td:first-child {
  width: 4em;
  min-width: 4em;
  max-width: 4em;
  word-break: break-all;
}
.products-c .filter-container{
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
}