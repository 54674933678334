.answer_option{
    display: flex;
    width: 100%;
    cursor: pointer;
    margin: 12px;
    padding: 6px;
    border-radius: 6px;
}
.answer_option.answer{
    background-color: rgb(120 242 146);
}
.answer_option.wrong{
    background-color: rgb(242, 144, 120);
}

.option_num{
    width: 38px;
    height: 38px;
    display: flex;
    font-family: 'Open Sans',Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    font-weight: 500;
    color: rgb(95, 94, 94);
    justify-content: center;
    user-select: none;
    border-radius: 50%;
    border: 1px solid #ccc;
}
.question_option.answer .option_num{
    border: 2px solid rgb(247, 245, 245);
    color: rgb(114, 112, 112)
}
.option_content{
    flex: 1;
    margin-left: 12px;
    margin-right: 2px;
    border-radius: 8px;
    padding: 8px;
    background-color: #f2f0f0;
}