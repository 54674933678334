.mock-add-header{
    display: flex;
    padding: 8px 14px;
}
.mock-h-text{
    flex: 1;
    align-self: center;
}
.mock-h-add{
    padding: 4px 10px !important;
    border-radius: 16px !important;
    text-transform: none !important;
}