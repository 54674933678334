.mock-category-c{
    flex: .25;
    border-right: 1px solid #ccc;
    height: 80vh;
}
.mock-topic-c{
    flex: .25;
    border-right: 1px solid #ccc;
    height: 80vh;
    padding-left: 12px;
}
.mock-questions-c{
    flex: .5;
    height: 80vh;
    padding-left: 12px;
}
.category-content{
    height: 100%;
    overflow-y: auto
}
