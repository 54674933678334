.left_menu_box{
    z-index: 2;
    width: 200px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: white
}
.rd-table-container{
    overflow-x: auto;
}
.home table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
.home th, td {
    text-align: left;
    padding: 8px;
  }
  
.home tr:nth-child(even){background-color: #f2f2f2}

.left_menu_box .MuiIconButton-root{
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgba(104, 104, 104, 0.24);
}
.left_menu_box.collapse{
    width: 70px;
}
.card_info_c{
    display: flex;
}
/* Responsive for mobile screen */
@media screen and (max-width: 640px) {
    /* .left_menu_box{
        display: none;
    } */
    .products-c .filter-container{
        flex-direction: column;        
    }
    .products-c .filter-container >div{
        margin-bottom: 12px;
        width: 100%;
    }
    .card_info_c{
        flex-direction: column;
        margin-bottom: 12px;
    }
    .card_info_c .card_info{
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .chart_c .chart_item{
        display: block;
        width: 100%;
    }
    .add-product .price-input-c{
        flex-direction: column;
    }
}