  .bill_container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 210mm; 
    height: 297mm; 
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    page-break-after: always; 
}
.header_info {
    text-align: center;
    margin-bottom: 20px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.header_info > div {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
}

.bill_no_line table {
    border-collapse: collapse;
    border-spacing: 0;
    border: none !important;
}

.bill_no_line th, .bill_no_line td {
    border: none !important;
}
.bill_no_line,
.bill_items {
    background-color: #ecf0f1;
    padding: 10px;
    margin-bottom: 20px;
}

.bill_container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.bill_container table,
.bill_container th,
.bill_container td {
    border: 1px solid #34495e;
}

.bill_container th,
.bill_container td {
    padding: 8px;
    text-align: center;
    font-size: 14px;
    width: 33.33%;
}

.bill_container th {
    background-color: #2c3e50;
    color: #ecf0f1;
}

.bill_items table {
    background-color: #fff;
}
.sign_content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.sign_content td {
    padding: 10px;
    text-align: center;
}

/* Additional styles */
span {
    font-weight: bold;
}

/* Disclaimer style */
.disclaimer {
    margin-top: 20px;
    font-style: italic;
    color: #777;
    padding: 10px;
    font-size: 14px;
    background-color: #ecf0f1;
}
.bill_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #ecf0f1;
    color: #777;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}