.price-input-c{
    display: flex;
    margin-top: 28px;
    position: relative;
    padding: 4px;
    border: 1px solid rgb(237, 236, 236);
    border-radius: 4px;
}
.price-input-c>div{
    margin: 6px;
}