.empty-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.empty-c-l{
    align-self: center;
    font-weight: 600;
    font-size: 20px;
}