.add-price-item-s{
    display: inline-flex;
    position: relative;
    margin: 2px;
    padding: 4px;
    border: 1px solid rgb(233, 230, 230);
    border-radius: 4px
}
.add-price-item-s .MuiIconButton-root{
    background-color: #edebeb;
    padding: 1px;
}